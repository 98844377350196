.container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(220, 228, 241);
    overflow-wrap: break-word;
    text-align: center;
    max-width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: rgb(51, 72, 104);
    padding: 20px;
    border-radius: 5px;
}

.endpoint_container {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 500px;
    background-color: rgb(31, 44, 63);
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.endpoint_container_top {
    background-color: rgb(51, 72, 104);
    padding: 10px;
    margin: -20px;
    margin-bottom: 10px;
}

.endpoint_container_bottom {
    text-align: left;
    color: rgb(174, 188, 209);
}

.bold {
    font-weight: bold;
}

.title {
    display: inline-flex;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid rgb(51, 72, 104);
    font-weight: bold;
    color: rgb(198, 211, 231);
}

.code {
    font-size: 12px;
    padding: 5px;
    background: rgb(32, 32, 32);
    border-radius: 5px;
}

@media only screen and (max-width: 540px) {
    .container {
        width: 100%;
    }
    .column {
        width: 90vw;
        margin-right: 40px;
    }
    .content {
        width: 90vw;
        margin-right: 40px;
    }
    .endpoint_container {
        width: 90vw;
        margin-right: 40px;
    }

}