.tooltip {
    position: relative;
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
}

.tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    margin-top: -45px;
    background-color: black;
    border-radius: 5px;
    padding: 8px 8px;
    color: white;
    font-weight: bold;
    opacity: 0;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;  
    transition: opacity 0.2s;  
}
