.tooltip {
    position: relative;
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    z-index: 5;
}

.tooltip .tooltiptext {
    position: absolute;
    margin-top: 37px;
    background-color: black;
    color: white;
    overflow: hidden;
}

.tooltip .tooltiptext ul li {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.ul li:nth-of-type(even) {
    background-color: rgb(65, 35, 50);
}