.search_box {
    width: 200px;
    position: relative;
    outline: none;
    margin-bottom: 30px;
}

.search_box input[type="text"] {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    border: none;
    color: rgb(198, 211, 231);
    font-size: 15px;
    outline: none;
    padding-right: 40px;
    /* background-color:rgb(29, 38, 54); */
    
    background-color: rgb(38, 50, 71);
    border-radius: 5px;
}

/* .search_box input[type=text]:focus {
    border-bottom: 2px solid rgb(130, 170, 240);
} */

.search_icon {
    position: absolute;
    top: 22px;
    right: 15px;
    transform: translateY(-50%);
    fill: rgb(95, 124, 175);
    width: 20px;
    cursor: pointer;
}

.search_icon:hover {
    fill: rgb(130, 170, 240);
}

.arrow {
    width: 20px;
    fill: #91a6c7;
    position: absolute;
    left: -40px;
    top: 12.5px;
}

.arrow:hover {
    fill: #4c5869;
    cursor: pointer;
}