.container {
    color: rgb(198, 211, 231);
    text-align: center;
    display: flex;
    justify-content: center;
}

.section {
    max-width: 600px;
}

.p {
    margin-top: 10px;
    margin-bottom: 20px;
}

.title {
    color: rgb(250, 252, 255);
    display: inline-block;
    font-size: 20px;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.video {
	position:relative;
	padding-bottom:56.25%;
	height:0;
	overflow:hidden;
}

.video iframe, .video object, .video embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

@media only screen and (max-width: 600px) {
    .section {
        position: absolute;
        max-width: 95%;
    }

    /* .video iframe {
        max-width: 95%;
        height: auto;
    } */
}