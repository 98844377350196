.container {
    display: flex;
    justify-content: center;
    color: rgb(198, 211, 231);
    overflow-wrap: break-word;
    text-align: center;
    max-width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: rgb(31, 44, 63);
    padding: 10px;
    border-radius: 5px;
}

.steamids {
    width: 260px;
    font-size: 16px;
    background-color: rgb(45, 62, 87);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.steamid {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 500px) {
    .content {
        width: 100vw;
    }

    .steamids {
        width: 240px;
        font-size: 14px;
    }
}