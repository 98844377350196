.container {
    display: flex;
    justify-content: center;
    max-width: 100%;
}

.table_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spinner {
    margin-top: 20px;
}

.search_wrapper {
    display: flex;
    justify-content: center;
    position: relative;
}

.more_button {
    font-size: 15px;
    font-weight: bold;
    color: rgb(198, 211, 231);
    background-color: rgb(44, 59, 83);
    border-radius: 5px;
    padding: 10px;
    display: inline-block;
    margin-top: 20px;
    cursor: pointer;
}

.ul {
    list-style: none;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.ul li:nth-of-type(even) {
    background-color: rgb(18, 24, 34);
}

.ul li:hover {
    background-color: rgb(65, 86, 122);
    text-decoration: none;
}

.ul a {
    color: inherit;
}