* {
    margin: 0;
    padding: 0;
    font-family: 'Play', 'Electrolize', 'Nunito', sans-serif;
}

body {
    /* background-color: #040609; */
    background-color: rgb(18, 24, 34);
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}

.container {
    background-color:rgb(29, 38, 54);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 1152px;
    box-shadow: rgb(54, 71, 100) 0px 0px 20px 1px; 
}

a:link {
    text-decoration: none;
}

.link {
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.25);
    cursor: pointer;
}

.link:hover {
    text-decoration-color: rgba(255, 255, 255, 0.75);
}
  