.container {
    display: flex;
    justify-content: center;
    min-height: 150px;
    font-size: 20px;
    color: white;
}

.options {
    margin: auto 0;
    width: 60%;
}

.option {
    margin: 10px;
    display: flex;
    justify-content: space-between;
}

.box {
    color: rgb(207, 207, 207);
    background-color: rgb(48, 64, 90);
    outline: none;
    border: none;
    border-bottom: 2px solid rgb(95, 124, 175);
    width: 163px;
}

.dropdown {
    width: 163px;
    color: white;

    outline: 0;
    box-shadow: none;
    border: 0;
    background-color: rgb(95, 124, 175);
    background-image: none;
    border-radius: 3px;
}

.save {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.button {
    border-radius: 5px;
    padding: 5px 20px;
    background-color: rgb(72, 96, 138);
    cursor: pointer;
    font-size: 18px;
}

.search_box {
    width: 200px;
    position: relative;
    outline: none;
    margin-bottom: 20px;
}

.search_box input[type="text"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid rgb(95, 124, 175);
    color: rgb(198, 211, 231);
    font-size: 15px;
    outline: none;
    padding-right: 40px;
    background-color:rgb(29, 38, 54);
}

.search_box input[type=text]:focus {
    border-bottom: 2px solid rgb(130, 170, 240);
}

.search_icon {
    position: absolute;
    top: 14px;
    right: 15px;
    transform: translateY(-50%);
    fill: rgb(95, 124, 175);
    width: 20px;
    cursor: pointer;
}

.search_icon:hover {
    fill: rgb(130, 170, 240);
}

.arrow {
    width: 20px;
    fill: #91a6c7;
    position: absolute;
    left: -40px;
    top: 12.5px;
}

.arrow:hover {
    fill: #4c5869;
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .options {
        width: 80%;
    }
}

@media only screen and (max-width: 400px) {
    .options {
        width: 100%;
    }
}