.container {
    display: flex;
    color: rgb(198, 211, 231);
}

.container_inv {
    flex-flow: row wrap;
    justify-content: center;
    text-align: center;
    color: rgb(198, 211, 231);
}

.avatar {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */

    margin-top: 5px;
    /* width: 128px; */
    border-radius: 5px;
    box-shadow: rgb(75, 99, 139) 0px 0px 20px 1px; 
    margin-bottom: 10px;
    cursor: pointer;
}

.map {
    color: white;
    background-color: rgb(73, 97, 139);
    border-radius: 5px;
    padding: 5px;
    font-weight: bold;
    word-wrap: break-word;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 5px;
}

.map_container {
    margin-top: 5px;
    margin-bottom: 15px;
    color: rgb(73, 97, 136);
}

.map_id_container {
    font-weight: bold;
    cursor: pointer;
}

.map_id_container:hover {
    color: rgb(116, 151, 212);
}

.left {
    width: 71%;
}

.middle {
    width: 4%;
}

.right {
    width: 128px;
    text-align: center;
    font-weight: bold;
}

.diff {
    color: rgb(255, 60, 93);
    font-size: 70%;
    font-weight: bold;
}

.wr {
    color: rgb(52, 233, 88);
    font-size: 70%;
    font-weight: bold;
}

.title {
    margin-top: 5px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

.title_small {
    margin-top: 5px;
}

.player_name {
    cursor: pointer;
    overflow-wrap: break-word;
}

.t {
    cursor: default;
}

.admin {
    display: inline-block;
    background-color: rgb(221, 43, 88);
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.textarea {
    margin-top: 20px;
    resize: none;
    margin-bottom: 20px;
}

.center {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.middle_avatar {
    display: none;
}

.vid {
    color: white;
    background-color: rgb(250, 50, 50);
    border-radius: 5px;
    padding: 5px;
    padding-right: 10px;
    font-weight: bold;
    word-wrap: break-word;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
}

.vidCenter {
    display: flex;
}

.editVid {
    background-color: rgb(151, 107, 107);
    border-radius: 5px;
    padding: 5px;
    padding-right: 10px;
    margin-top: 10px;
    font-weight: bold;
    cursor: pointer;
}

.yt {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

@media only screen and (max-width: 450px) {
    .right {
        display: none;
    }

    .middle {
        display: none;
    }
    
    .left {
        width: 100%;
    }

    .map_container {
        text-align: center;
    }

    .middle_avatar {
        display: flex;
        justify-content: center;
        flex-flow: column;
        text-align: center;
        font-weight: bold;
    }

    .avatar {
        width: 128px;
    }

    .textarea {
        width: 100%;
    }
}