.container {
    display: flex;
    justify-content: center;
    color: rgb(198, 211, 231);
    overflow-wrap: break-word;
    max-width: 100%;
}

.admin_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    color: rgb(198, 211, 231);
    border-top: 2px solid rgb(58, 78, 110);
    margin-top: 20px;
}

.badges_container {
    border-top: 0;
}

.content {
    max-width: 425px;
    margin: auto;
    background-color: rgb(42, 56, 78);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.table_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.left {
    width: 33%;
}

.mid {
    width: 33%;
}

.right {
    width: 33%;
    text-align: center;
}

.right2 {
    width: 33%;
}

.text {
    margin-top: 20px;
}

.avatar {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
    border-radius: 5px;
    box-shadow: rgb(75, 99, 139) 0px 0px 20px 1px; 
}

.title {
    margin-top: 5px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

.title1 {
    margin-top: 0px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

.setup_container {
    border-top: 2px solid rgb(58, 78, 110);
    margin-top: 10px;
    padding-top: 20px;
    width: 100%;
}

.setup_left {
    float: left;
    width: 50%;
}

.setup_right {
    float: right;
    width: 50%;
}

.button {
    border-radius: 5px;
    padding: 5px 20px;
    background-color: rgb(72, 96, 138);
    cursor: pointer;
    font-size: 18px;
}

.badges {
    display: inline-block;
    font-weight: bold;
    color: rgb(255, 255, 255);
    background-color: rgb(72, 96, 138);
    padding: 8px;
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 5px;
    cursor: default;
}

.admin {
    background-color: transparent;
    margin-top: 10px;
    margin-bottom: -10px;
    cursor: pointer;
}

.ul {
    list-style: none;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.ul li:nth-of-type(even) {
    background-color: rgb(18, 24, 34);
}

.ul li:hover {
    background-color: rgb(65, 86, 122);
    text-decoration: none;
}

.ul a {
    color: inherit;
}

@media only screen and (max-width: 600px) {
    .content {
        width: 93vw;
        margin: auto;
        background-color: rgb(42, 56, 78);
        border-radius: 0;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
}