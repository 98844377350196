.time_decimal {
    font-size: 70%;
    color: rgb(198, 211, 231, 0.5);
}

.red {
    color: rgb(255, 60, 93);
    font-size: 80%;
    font-weight: bold;
}

.green {
    color: rgb(52, 233, 88);
    font-size: 80%;
    font-weight: bold;
}

.wrap {
    display: inline-flex;
}

.flag {
    position: relative;
    margin-right: 7px;
    margin-top: 3px;
}