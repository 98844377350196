.table_wrapper {
    padding: 0px 20px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}

.table {
    table-layout: fixed;
    border-radius: 20px;
    width: 100%;
}

.table_title {
    display: inline-block;
    font-size: 15px;
    color: rgb(230, 237, 248);
    background-color: rgb(56, 74, 105);
    border-radius: 5px;
    padding: 10px;
    margin: -10px;
    margin-top: 0px;
    margin-bottom: 20px;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.ul {
    list-style: none;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}


.ul li:nth-of-type(even) {
    background-color: rgb(18, 24, 34);
}

.ul li:hover {
    background-color: rgb(65, 86, 122);
    text-decoration: none;
}

.drop {
    margin-left: 5px;
    margin-top: 20px;
    fill: rgb(115, 151, 214);
}

.links a {
    color: rgb(202, 202, 202);
    text-decoration: none;
}

.content-table {
    border-collapse: separate;
    border-radius: 5px;
    border-spacing: 0px;
    border: 2px solid rgb(56, 74, 105);
    text-align: left;
    font-size: 14px;
    width: 500px;
    height: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
}

/* .content-table {
    background: coral;
    border-collapse: collapse;
    border-radius: 5px;
    text-align: left;
    font-size: 0.9em;
    width: 500px;
    height: 30px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
    z-index: 1;
} */

.content-table tr {
    width: 500px;
    height: 30px;
}

.content-table thead tr {
    border-radius: 5px 5px 0 0;
    text-align: left;
    font-weight: bold;
    background-color: rgb(56, 74, 105);
    cursor: default;
}

.content-table th,
.content-table td {
    /* padding: 7px 9px; */
    padding: 7px 9px;
    height: 20px;
    max-height: 20px;
    color: rgb(198, 211, 231);
}

.content-table th:not(.dif),
.content-table td:not(.dif) {
    /* padding: 7px 9px; */
    width: 33%;
}

.content-table tbody tr:nth-of-type(even) {
    background-color: rgb(35, 46, 65);
}

.content-table tbody tr:nth-of-type(odd) {
    background-color: rgb(40, 53, 75);
}

.content-table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.not_found {
    margin-top: 10px;
    color: rgb(198, 211, 231);
    font-size: 120%;
}

.dif {
    width: 20px;
}

@media only screen and (max-width: 600px) {
    .table_wrapper table {
        width:  100vw; /* For 100% screen width */
        border-radius: 0;
    }

    .content-table th,
    .content-table td {
        font-size: 90%;
        padding: 3px 5px;
        height: 20px;
        max-height: 210px;
        color: rgb(198, 211, 231);
    }

}

@media only screen and (max-width: 330px) {
    .content-table th,
    .content-table td {
        font-size: 75%;
        padding: 2px 4px;
        height: 20px;
        max-height: 210px;
        color: rgb(198, 211, 231);
    }

}