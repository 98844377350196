.container {
    min-height: 125px;
    margin-top: 10vh;
    background: linear-gradient(0deg, rgba(11,11,11,1) 0%, rgba(29,38,54,1) 100%);
    display: flex;
    justify-content: center;
}

.links {
    display: inline-flex;
}

.ul {
    padding: 0 20px 0 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgb(157, 176, 204);
    font-size: 18px;
    list-style: none;
}

.li {
    cursor: pointer;
}

.ul a {
    text-decoration: none;
    color: rgb(157, 176, 204);
}

.first {
    font-size: 20px;
    color: rgb(216, 226, 241);
}

@media only screen and (max-width: 460px) {
    .links {
        font-size: 16px;
    }

    .ul {
        font-size: 16px;
    }
}

@media only screen and (max-width: 350px) {
    .links {
        font-size: 13px;
    }

    .ul {
        font-size: 13px;
    }
}