.more_button {
    font-size: 15px;
    font-weight: bold;
    color: rgb(198, 211, 231);
    background-color: rgb(44, 59, 83);
    border-radius: 5px;
    padding: 10px;
    display: inline-block;
    margin-top: 20px;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}