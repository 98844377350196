.modal {
    z-index: 15;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
}

.modal-box {
    border-radius: 5px;
    background-color:rgb(48, 64, 90);
    margin: 20vh auto; /* 15% from the top and centered */
    padding: 20px;
    max-width: 475px;
    min-height: 150px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    box-shadow: rgb(0, 0, 0) 0px 0px 50px 0.7px;
    padding-right: 44px;
}

.modal-close {
    position: absolute;
    color: #aaa;
    font-size: 28px;
    margin-top: -14px;
    right: 14px;
    font-weight: bold;
}

.modal-close:hover,
.modal-close:focus {
    color: rgb(116, 116, 116);
    text-decoration: none;
    cursor: pointer;
}

.modal-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

@media only screen and (max-width: 541px) {
    .modal-box {
        border-radius: 0;
    }
}