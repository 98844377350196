/* .table_wrapper {
    display: flex;
    justify-content: center;
    text-align: right;
} */

.table_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.name {
    font-weight: bold;
}

span[class*='rank_'] {
    font-weight: bold;
}

.rank_1 {
    color: gold;
    text-shadow: 0 0 10px gold;
    font-size: 15px;
}

.rank_2 {
    color: silver;
    text-shadow: 0 0 10px rgb(255, 255, 255);
    font-size: 15px;
}

.rank_3 {
    color: #cd7f32;
    text-shadow: 0 0 10px #cd7f32;
    font-size: 15px;
}

.rank_10up {
    text-shadow: 0 0 10px rgb(95, 95, 95);
}

.ul {
    list-style: none;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.ul li:nth-of-type(even) {
    background-color: rgb(18, 24, 34);
}

.ul li:hover {
    background-color: rgb(65, 86, 122);
    text-decoration: none;
}