body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip_tooltip__2vJfc {
    position: relative;
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
}

.tooltip_tooltip__2vJfc .tooltip_tooltiptext__3HLqv {
    visibility: hidden;
    position: absolute;
    margin-top: -45px;
    background-color: black;
    border-radius: 5px;
    padding: 8px 8px;
    color: white;
    font-weight: bold;
    opacity: 0;
}

.tooltip_tooltip__2vJfc .tooltip_tooltiptext__3HLqv::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip_tooltip__2vJfc:hover .tooltip_tooltiptext__3HLqv {
    visibility: visible;
    opacity: 1;  
    transition: opacity 0.2s;  
}

.flags-list_flag__-BeAH {
    width: 15px;
    border-radius: 1.5px;
}
.funcs_time_decimal__N-Egi {
    font-size: 70%;
    color: rgb(198, 211, 231, 0.5);
}

.funcs_red__1BZSx {
    color: rgb(255, 60, 93);
    font-size: 80%;
    font-weight: bold;
}

.funcs_green__1WrUx {
    color: rgb(52, 233, 88);
    font-size: 80%;
    font-weight: bold;
}

.funcs_wrap__3zFJA {
    display: inline-flex;
}

.funcs_flag__3hlOt {
    position: relative;
    margin-right: 7px;
    margin-top: 3px;
}
.top_topnav__3Q5t7 {
    margin-bottom: 10px;
    height: 150px;
    
    /* background: url("../../../../src/assets/banner.png"); */
    /* border-bottom: 2px solid white; */
    /* box-shadow: rgb(75, 99, 139) 0px 0px 20px 1px;  */
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
    /* background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); */

    background-color:rgb(29, 38, 54);
    background-image: linear-gradient(
      to bottom, transparent, rgb(29, 38, 54)
    ), url(/static/media/banner.7c294083.png);
    background-repeat: no-repeat;

}

.top_link_wrapper__1G0BP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.top_nav-links__3qEV8 {
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    display: inline-flex;
    font-size: 1.75em;
    list-style: none;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
}

.top_t__2E0u- {
    margin-top: 10px;
    padding: 5px 10px 5px 10px;
    font-size: 1.25em;
}

/* background-color: rgb(0, 0, 0);
background-color: rgba(0, 0, 0, 0.5); */

.top_nav-links__3qEV8 a {
    color: rgb(255, 255, 255);
    margin-right: 20px;
}

.top_nav-links__3qEV8 > li:last-child {
    margin-right: -20px;
}

.top_list_item_wrapper__2SgH7 {
    display: inline-flex;
    align-items: center;
}

.top_pic__3XJWX {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border-radius: 100%;
}

.top_dropdown__15qg0 {
    position: absolute;
    z-index: 2;
    background-color: black;
}

.top_top_mobile__23HZN {
    display: none;
    justify-content: space-between;
    background-color:rgb(29, 38, 54);
    background-image: linear-gradient(
      to bottom, transparent, rgb(29, 38, 54)
    ), url(/static/media/banner.7c294083.png);
    background-repeat: no-repeat;
    max-width: 100%;
    width: 100vw;
    height: 60px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    z-index: 10;

    color: rgb(231, 231, 231);
    vertical-align: middle;
    line-height: 20px;
}

.top_shadow__2tSw6 {
    box-shadow: 0 0 20px rgba(0, 0, 0, 1);
}

.top_menu_link__1qkXl {
    padding: 20px;
}

.top_menu_link__1qkXl .top_signed_out__16bD7 {
    display: none;
}

.top_menu_link__1qkXl > a {
    text-decoration: none;
    color: rgb(231, 231, 231);
    font-size: 20px;
}

.top_menu_icon__2GigM {
    margin-top: 2px;
    padding: 14px;
    margin-left: 6px;
}

.top_menu_icon__2GigM div {
    width: 24px;
    height: 2px;
    background-color: rgb(231, 231, 231);
    margin: 6px 0;
}

.top_dropdown_menu__2FrCW {
    min-height: 60px;
    margin-top: 60px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    background-color:rgb(29, 38, 54);
    
    z-index: 9;
}

.top_dropdown_menu__2FrCW ul li a {
    text-decoration: none;
    color: rgb(231, 231, 231);
}

.top_dropdown_menu__2FrCW ul li {
    list-style: none;
    padding: 7.5px;
    margin-left: 15px;
    margin-right: 15px;
}

.top_dropdown_menu__2FrCW ul li:last-child {
    margin-bottom: 10px;
}

.top_dropdown_divider__3YsyK {
    display: flex;
    justify-content: space-between;
}

.top_xmas_lights_mobile__3kIv9 {
    background-color: blue;
    visibility: hidden;
}

@media only screen and (max-width: 870px) {
    .top_topnav__3Q5t7 {
        max-width: 100%;
        display: none;
    }

    .top_top_mobile__23HZN {
        display: flex;
    }

    .top_dropdown_menu__2FrCW {
        display: '';
    }

    .top_xmas_lights__3lXIy {
        visibility: hidden;
    }

    .top_xmas_lights_mobile__3kIv9 {
        z-index: 6;
        position: fixed;
        visibility: visible;
    }
}

@media only screen and (min-width: 870px) {
    .top_dropdown_menu__2FrCW {
        display: none;
    }
}

#top-christmas_wire__1aIh2 {
    pointer-events: none;
    text-align: center;
    white-space: nowrap;
    position: absolute;
    padding: 0;
    min-width: 1152px;
    top: 40px;
    border-bottom: 3px solid #222;
    height: 100px;
}

#top-christmas_wire__1aIh2 li {
    pointer-events: none;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 15px;
    height: 30px;
    border-radius: 50%;
    margin: 0px 15px;
    top: 102px;
    display: inline-block;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: top-christmas_even-flash__37NQr;
    animation-duration: 3s;
}

#top-christmas_wire__1aIh2 li:nth-child(odd) {
    animation-name: top-christmas_odd-flash__3vC7b;
    animation-duration: 3s;
}

#top-christmas_wire__1aIh2 li:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 10px;
    border-radius: 4px;
    top: -5px;
    left: 0px;
    background: #444;
}

@keyframes top-christmas_even-flash__37NQr {
    0%,
    100% {
        background: rgba(30, 162, 86, 1);
        box-shadow: 0px 2px 20px 4px rgba(30, 162, 86, 1);
    }
    50% {
        background: rgba(30, 162, 86, 0.5);
        box-shadow: 0px 2px 20px 4px rgba(30, 162, 86, 0.3);
    }
}

@keyframes top-christmas_odd-flash__3vC7b {
    50% {
        background: rgba(187, 37, 40, 1);
        box-shadow: 0px 2px 20px 4px rgba(187, 37, 40, 1);
    }
    0%,
    100% {
        background: rgba(187, 37, 40, 0.5);
        box-shadow: 0px 2px 20px 4px rgba(187, 37, 40, 0.3);
    }
}

@media only screen and (max-width: 870px) {
    #top-christmas_wire__1aIh2 {
        pointer-events: none;
        text-align: center;
        white-space: nowrap;
        position: absolute;
        padding: 0;
        min-width: 1152px;
        top: -40px;
        border-bottom: 3px solid #222;
        height: 100px;
        z-index: 2;
    }
}
.players_container__1UO3J {
    display: flex;
    justify-content: center;
    color: rgb(198, 211, 231);
    overflow-wrap: break-word;
    max-width: 100%;
}

.players_admin_container__1lHvq {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    color: rgb(198, 211, 231);
    border-top: 2px solid rgb(58, 78, 110);
    margin-top: 20px;
}

.players_badges_container__3pMFQ {
    border-top: 0;
}

.players_content__1uBxF {
    max-width: 425px;
    margin: auto;
    background-color: rgb(42, 56, 78);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.players_table_wrapper__1dequ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.players_left__3xGtX {
    width: 33%;
}

.players_mid__3MRi- {
    width: 33%;
}

.players_right__1InXc {
    width: 33%;
    text-align: center;
}

.players_right2__5mSdM {
    width: 33%;
}

.players_text__2eaFi {
    margin-top: 20px;
}

.players_avatar__29F-W {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
    border-radius: 5px;
    box-shadow: rgb(75, 99, 139) 0px 0px 20px 1px; 
}

.players_title__2SVB4 {
    margin-top: 5px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

.players_title1__26-8n {
    margin-top: 0px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

.players_setup_container__27CUR {
    border-top: 2px solid rgb(58, 78, 110);
    margin-top: 10px;
    padding-top: 20px;
    width: 100%;
}

.players_setup_left__1p_iU {
    float: left;
    width: 50%;
}

.players_setup_right__1SWtn {
    float: right;
    width: 50%;
}

.players_button__2VmSb {
    border-radius: 5px;
    padding: 5px 20px;
    background-color: rgb(72, 96, 138);
    cursor: pointer;
    font-size: 18px;
}

.players_badges__1k7Wk {
    display: inline-block;
    font-weight: bold;
    color: rgb(255, 255, 255);
    background-color: rgb(72, 96, 138);
    padding: 8px;
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 5px;
    cursor: default;
}

.players_admin__19qTs {
    background-color: transparent;
    margin-top: 10px;
    margin-bottom: -10px;
    cursor: pointer;
}

.players_ul__2V8py {
    list-style: none;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.players_ul__2V8py li:nth-of-type(even) {
    background-color: rgb(18, 24, 34);
}

.players_ul__2V8py li:hover {
    background-color: rgb(65, 86, 122);
    text-decoration: none;
}

.players_ul__2V8py a {
    color: inherit;
}

@media only screen and (max-width: 600px) {
    .players_content__1uBxF {
        width: 93vw;
        margin: auto;
        background-color: rgb(42, 56, 78);
        border-radius: 0;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
}
.searchbox_search_box__3JcUT {
    width: 200px;
    position: relative;
    outline: none;
    margin-bottom: 30px;
}

.searchbox_search_box__3JcUT input[type="text"] {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    border: none;
    color: rgb(198, 211, 231);
    font-size: 15px;
    outline: none;
    padding-right: 40px;
    /* background-color:rgb(29, 38, 54); */
    
    background-color: rgb(38, 50, 71);
    border-radius: 5px;
}

/* .search_box input[type=text]:focus {
    border-bottom: 2px solid rgb(130, 170, 240);
} */

.searchbox_search_icon__2lNVJ {
    position: absolute;
    top: 22px;
    right: 15px;
    transform: translateY(-50%);
    fill: rgb(95, 124, 175);
    width: 20px;
    cursor: pointer;
}

.searchbox_search_icon__2lNVJ:hover {
    fill: rgb(130, 170, 240);
}

.searchbox_arrow__2larb {
    width: 20px;
    fill: #91a6c7;
    position: absolute;
    left: -40px;
    top: 12.5px;
}

.searchbox_arrow__2larb:hover {
    fill: #4c5869;
    cursor: pointer;
}
.list_table_wrapper__3yhRT {
    padding: 0px 20px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}

.list_table__3Qo0Q {
    table-layout: fixed;
    border-radius: 20px;
    width: 100%;
}

.list_table_title__1ua1K {
    display: inline-block;
    font-size: 15px;
    color: rgb(230, 237, 248);
    background-color: rgb(56, 74, 105);
    border-radius: 5px;
    padding: 10px;
    margin: -10px;
    margin-top: 0px;
    margin-bottom: 20px;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.list_ul__2eZ2w {
    list-style: none;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}


.list_ul__2eZ2w li:nth-of-type(even) {
    background-color: rgb(18, 24, 34);
}

.list_ul__2eZ2w li:hover {
    background-color: rgb(65, 86, 122);
    text-decoration: none;
}

.list_drop__3U5rO {
    margin-left: 5px;
    margin-top: 20px;
    fill: rgb(115, 151, 214);
}

.list_links__2WfLO a {
    color: rgb(202, 202, 202);
    text-decoration: none;
}

.list_content-table__2alYq {
    border-collapse: separate;
    border-radius: 5px;
    border-spacing: 0px;
    border: 2px solid rgb(56, 74, 105);
    text-align: left;
    font-size: 14px;
    width: 500px;
    height: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
}

/* .content-table {
    background: coral;
    border-collapse: collapse;
    border-radius: 5px;
    text-align: left;
    font-size: 0.9em;
    width: 500px;
    height: 30px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
    z-index: 1;
} */

.list_content-table__2alYq tr {
    width: 500px;
    height: 30px;
}

.list_content-table__2alYq thead tr {
    border-radius: 5px 5px 0 0;
    text-align: left;
    font-weight: bold;
    background-color: rgb(56, 74, 105);
    cursor: default;
}

.list_content-table__2alYq th,
.list_content-table__2alYq td {
    /* padding: 7px 9px; */
    padding: 7px 9px;
    height: 20px;
    max-height: 20px;
    color: rgb(198, 211, 231);
}

.list_content-table__2alYq th:not(.list_dif__1lp-d),
.list_content-table__2alYq td:not(.list_dif__1lp-d) {
    /* padding: 7px 9px; */
    width: 33%;
}

.list_content-table__2alYq tbody tr:nth-of-type(even) {
    background-color: rgb(35, 46, 65);
}

.list_content-table__2alYq tbody tr:nth-of-type(odd) {
    background-color: rgb(40, 53, 75);
}

.list_content-table__2alYq tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.list_not_found__BxNsG {
    margin-top: 10px;
    color: rgb(198, 211, 231);
    font-size: 120%;
}

.list_dif__1lp-d {
    width: 20px;
}

@media only screen and (max-width: 600px) {
    .list_table_wrapper__3yhRT table {
        width:  100vw; /* For 100% screen width */
        border-radius: 0;
    }

    .list_content-table__2alYq th,
    .list_content-table__2alYq td {
        font-size: 90%;
        padding: 3px 5px;
        height: 20px;
        max-height: 210px;
        color: rgb(198, 211, 231);
    }

}

@media only screen and (max-width: 330px) {
    .list_content-table__2alYq th,
    .list_content-table__2alYq td {
        font-size: 75%;
        padding: 2px 4px;
        height: 20px;
        max-height: 210px;
        color: rgb(198, 211, 231);
    }

}
.tooltip-bottom_tooltip__G51Ty {
    position: relative;
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    z-index: 5;
}

.tooltip-bottom_tooltip__G51Ty .tooltip-bottom_tooltiptext__1ZL6L {
    position: absolute;
    margin-top: 37px;
    background-color: black;
    color: white;
    overflow: hidden;
}

.tooltip-bottom_tooltip__G51Ty .tooltip-bottom_tooltiptext__1ZL6L ul li {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.tooltip-bottom_hidden__3VmxW {
    visibility: hidden;
}

.tooltip-bottom_visible__kGH-H {
    visibility: visible;
}

.tooltip-bottom_ul__2S6Kn li:nth-of-type(even) {
    background-color: rgb(65, 35, 50);
}
.pagination_container__2xWWc {
    cursor: pointer;
    display: flex;
    width: 100%;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.pagination_button__31RwD {
    font-size: 15px;
    font-weight: bold;
    color: rgb(198, 211, 231);
    background-color: rgb(44, 59, 83);
    padding: 8px;
    min-width: 20px;
    text-align: center;
}

.pagination_button__31RwD:hover {
    background-color: rgb(65, 87, 122);
}

.pagination_container__2xWWc ul li {
    list-style-type: none;
    display: inline-flex;
    justify-content: center;
}

.pagination_button__31RwD.pagination_active__3qFFI {
    background-color: rgb(86, 116, 165);
    color: rgb(231, 237, 247);
}

.pagination_button__31RwD.pagination_button_left__1qCCW {
    border-radius: 5px 0 0 5px;
}

.pagination_button__31RwD.pagination_button_right__1yeuN {
    border-radius: 0 5px 5px 0;
}

@media only screen and (max-width: 400px) {
    .pagination_button__31RwD {
        font-size: 90%;
        padding: 5px;
    }

    .pagination_button__31RwD.pagination_button_left__1qCCW {
        border-radius: 2px 0 0 2px;
    }
    
    .pagination_button__31RwD.pagination_button_right__1yeuN {
        border-radius: 0 2px 2px 0;
    }

}

@media only screen and (max-width: 330px) {
    .pagination_button__31RwD {
        padding: 2px;
    }
}
.spinner_lds-ripple__3JHhJ {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.spinner_lds-ripple__3JHhJ div {
    position: absolute;
    border: 4px solid rgb(173, 173, 173);
    opacity: 1;
    border-radius: 50%;
    animation: spinner_lds-ripple__3JHhJ 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.spinner_lds-ripple__3JHhJ div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes spinner_lds-ripple__3JHhJ {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.record_container__2XYjq {
    display: flex;
    color: rgb(198, 211, 231);
}

.record_container_inv__1n8XU {
    flex-flow: row wrap;
    justify-content: center;
    text-align: center;
    color: rgb(198, 211, 231);
}

.record_avatar__3EMRk {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */

    margin-top: 5px;
    /* width: 128px; */
    border-radius: 5px;
    box-shadow: rgb(75, 99, 139) 0px 0px 20px 1px; 
    margin-bottom: 10px;
    cursor: pointer;
}

.record_map__kYACv {
    color: white;
    background-color: rgb(73, 97, 139);
    border-radius: 5px;
    padding: 5px;
    font-weight: bold;
    word-wrap: break-word;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 5px;
}

.record_map_container__2qVs2 {
    margin-top: 5px;
    margin-bottom: 15px;
    color: rgb(73, 97, 136);
}

.record_map_id_container__1eQxB {
    font-weight: bold;
    cursor: pointer;
}

.record_map_id_container__1eQxB:hover {
    color: rgb(116, 151, 212);
}

.record_left__3nv8m {
    width: 71%;
}

.record_middle__1GUc1 {
    width: 4%;
}

.record_right__3IzLQ {
    width: 128px;
    text-align: center;
    font-weight: bold;
}

.record_diff__1UipE {
    color: rgb(255, 60, 93);
    font-size: 70%;
    font-weight: bold;
}

.record_wr__zf_fQ {
    color: rgb(52, 233, 88);
    font-size: 70%;
    font-weight: bold;
}

.record_title__gWNvz {
    margin-top: 5px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

.record_title_small__17tlu {
    margin-top: 5px;
}

.record_player_name__353dz {
    cursor: pointer;
    overflow-wrap: break-word;
}

.record_t__25GZt {
    cursor: default;
}

.record_admin__3xgDv {
    display: inline-block;
    background-color: rgb(221, 43, 88);
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.record_textarea__2P6au {
    margin-top: 20px;
    resize: none;
    margin-bottom: 20px;
}

.record_center__2o-Ny {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.record_middle_avatar__YdKIT {
    display: none;
}

.record_vid__3yst3 {
    color: white;
    background-color: rgb(250, 50, 50);
    border-radius: 5px;
    padding: 5px;
    padding-right: 10px;
    font-weight: bold;
    word-wrap: break-word;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
}

.record_vidCenter__hBwML {
    display: flex;
}

.record_editVid__o2hDF {
    background-color: rgb(151, 107, 107);
    border-radius: 5px;
    padding: 5px;
    padding-right: 10px;
    margin-top: 10px;
    font-weight: bold;
    cursor: pointer;
}

.record_yt__2spkQ {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

@media only screen and (max-width: 450px) {
    .record_right__3IzLQ {
        display: none;
    }

    .record_middle__1GUc1 {
        display: none;
    }
    
    .record_left__3nv8m {
        width: 100%;
    }

    .record_map_container__2qVs2 {
        text-align: center;
    }

    .record_middle_avatar__YdKIT {
        display: flex;
        justify-content: center;
        flex-flow: column;
        text-align: center;
        font-weight: bold;
    }

    .record_avatar__3EMRk {
        width: 128px;
    }

    .record_textarea__2P6au {
        width: 100%;
    }
}
.settings_container__2XHBh {
    display: flex;
    justify-content: center;
    min-height: 150px;
    font-size: 20px;
    color: white;
}

.settings_options__28dPR {
    margin: auto 0;
    width: 60%;
}

.settings_option__1PdyB {
    margin: 10px;
    display: flex;
    justify-content: space-between;
}

.settings_box__QKaEe {
    color: rgb(207, 207, 207);
    background-color: rgb(48, 64, 90);
    outline: none;
    border: none;
    border-bottom: 2px solid rgb(95, 124, 175);
    width: 163px;
}

.settings_dropdown__2YGQU {
    width: 163px;
    color: white;

    outline: 0;
    box-shadow: none;
    border: 0;
    background-color: rgb(95, 124, 175);
    background-image: none;
    border-radius: 3px;
}

.settings_save__3uFNU {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.settings_button__2UEik {
    border-radius: 5px;
    padding: 5px 20px;
    background-color: rgb(72, 96, 138);
    cursor: pointer;
    font-size: 18px;
}

.settings_search_box__DzKWN {
    width: 200px;
    position: relative;
    outline: none;
    margin-bottom: 20px;
}

.settings_search_box__DzKWN input[type="text"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid rgb(95, 124, 175);
    color: rgb(198, 211, 231);
    font-size: 15px;
    outline: none;
    padding-right: 40px;
    background-color:rgb(29, 38, 54);
}

.settings_search_box__DzKWN input[type=text]:focus {
    border-bottom: 2px solid rgb(130, 170, 240);
}

.settings_search_icon__2xRsU {
    position: absolute;
    top: 14px;
    right: 15px;
    transform: translateY(-50%);
    fill: rgb(95, 124, 175);
    width: 20px;
    cursor: pointer;
}

.settings_search_icon__2xRsU:hover {
    fill: rgb(130, 170, 240);
}

.settings_arrow__2RXSa {
    width: 20px;
    fill: #91a6c7;
    position: absolute;
    left: -40px;
    top: 12.5px;
}

.settings_arrow__2RXSa:hover {
    fill: #4c5869;
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .settings_options__28dPR {
        width: 80%;
    }
}

@media only screen and (max-width: 400px) {
    .settings_options__28dPR {
        width: 100%;
    }
}
.records_container__2Qx6t {
    display: flex;
    justify-content: center;
    max-width: 100%;
}

.records_table_wrapper__1ytDX {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.records_spinner__2pzu- {
    margin-top: 20px;
}

.records_search_wrapper__1xFnp {
    display: flex;
    justify-content: center;
    position: relative;
}

.records_more_button__372jY {
    font-size: 15px;
    font-weight: bold;
    color: rgb(198, 211, 231);
    background-color: rgb(44, 59, 83);
    border-radius: 5px;
    padding: 10px;
    display: inline-block;
    margin-top: 20px;
    cursor: pointer;
}

.records_ul__1uPQY {
    list-style: none;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.records_ul__1uPQY li:nth-of-type(even) {
    background-color: rgb(18, 24, 34);
}

.records_ul__1uPQY li:hover {
    background-color: rgb(65, 86, 122);
    text-decoration: none;
}

.records_ul__1uPQY a {
    color: inherit;
}
/* .table_wrapper {
    display: flex;
    justify-content: center;
    text-align: right;
} */

.leaderboards_table_wrapper__2Xr3m {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.leaderboards_name__3pljj {
    font-weight: bold;
}

span[class*='rank_'] {
    font-weight: bold;
}

.leaderboards_rank_1__Ft1fc {
    color: gold;
    text-shadow: 0 0 10px gold;
    font-size: 15px;
}

.leaderboards_rank_2__1t9oQ {
    color: silver;
    text-shadow: 0 0 10px rgb(255, 255, 255);
    font-size: 15px;
}

.leaderboards_rank_3__3ws-t {
    color: #cd7f32;
    text-shadow: 0 0 10px #cd7f32;
    font-size: 15px;
}

.leaderboards_rank_10up__1tFBi {
    text-shadow: 0 0 10px rgb(95, 95, 95);
}

.leaderboards_ul__3nD4u {
    list-style: none;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.leaderboards_ul__3nD4u li:nth-of-type(even) {
    background-color: rgb(18, 24, 34);
}

.leaderboards_ul__3nD4u li:hover {
    background-color: rgb(65, 86, 122);
    text-decoration: none;
}
.home_container__2U7dJ {
    color: rgb(198, 211, 231);
    text-align: center;
    display: flex;
    justify-content: center;
}

.home_section__JfEzi {
    max-width: 600px;
}

.home_p__1xeuX {
    margin-top: 10px;
    margin-bottom: 20px;
}

.home_title__1YGs_ {
    color: rgb(250, 252, 255);
    display: inline-block;
    font-size: 20px;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.home_video__XrA5h {
	position:relative;
	padding-bottom:56.25%;
	height:0;
	overflow:hidden;
}

.home_video__XrA5h iframe, .home_video__XrA5h object, .home_video__XrA5h embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

@media only screen and (max-width: 600px) {
    .home_section__JfEzi {
        position: absolute;
        max-width: 95%;
    }

    /* .video iframe {
        max-width: 95%;
        height: auto;
    } */
}
.community_container__2TQFu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    max-width: 100%;
}

.community_title__3enPI {
    display: inline-block;
    font-size: 18px;
    color: rgb(230, 237, 248);
    background-color: rgb(56, 74, 105);
    border-radius: 5px;
    padding: 10px;
    margin: -10px;
    margin-top: 0px;
    margin-bottom: 20px;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.community_ul__2Ni3Q {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.community_list_item__3Q5qo {
    list-style: none;
    justify-content: center;
    display: inline-flex;
    background-color: rgb(42, 56, 78);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 350px;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.community_list_item__3Q5qo:hover {
    background-color: rgb(54, 72, 100);
    color: rgb(223, 231, 245);
}

.community_pic_container__23qG3 {
    display: flex;
    width: 15%;
    align-items: center;
    justify-content: center;
    border-right: 2px solid rgb(67, 90, 126);
}

.community_pic__2BzGX {
    margin-right: 10px;
    border-radius: 100%;
    max-width: 32px;
    max-height: 32px;
    overflow: hidden;
    background-color: rgb(42, 56, 78);
}

.community_text_container__3wAwf {
    justify-content: center;
    text-align: center;
    width: 85%;
}

.community_list_item__3Q5qo .community_text__3n9on {
    color: rgb(148, 164, 189);
    margin-top: 5px;
}

.community_list_item_title__3Ugte {
    font-weight: bold;
    color: rgb(236, 236, 236);
}

.community_bottom__3P1rq {
    margin-top: 10px;
    font-weight: bold;
    color: rgb(198, 211, 231);
}

@media only screen and (max-width: 500px) {
    .community_ul__2Ni3Q {
        width: 100vw;
    }

    .community_list_item__3Q5qo {
        width: 100%;
        margin-right: 1vh;
    }

    .community_pic_container__23qG3 {
        width: 15vh;
        margin-left: 2vh;
    }

    .community_text_container__3wAwf {
        width: 75vh;
    }
}
.more-button_more_button__2hveK {
    font-size: 15px;
    font-weight: bold;
    color: rgb(198, 211, 231);
    background-color: rgb(44, 59, 83);
    border-radius: 5px;
    padding: 10px;
    display: inline-block;
    margin-top: 20px;
    cursor: pointer;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}
.servers_container__2_4ax {
    display: flex;
    justify-content: center;
    align-items: center;
}
.middle_main__3xpnm {
    margin-top: 30px;
    min-height: 80vh;
    color: rgb(198, 211, 231);
}

.middle_container__3umqG {
    display: flex;
    justify-content: center;
    align-items: center;
}

.middle_xmas__3dQrS {
    margin-top: 50px;
}

@media only screen and (max-width: 870px) {
    .middle_main__3xpnm {
        max-width: 100%;
        margin-top: 90px;
    }

    .middle_xmas__3dQrS {
        margin-top: 115px;
    }
}
.id-converter_container__2utXA {
    display: flex;
    justify-content: center;
    color: rgb(198, 211, 231);
    overflow-wrap: break-word;
    text-align: center;
    max-width: 100%;
}

.id-converter_content__2NLca {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: rgb(31, 44, 63);
    padding: 10px;
    border-radius: 5px;
}

.id-converter_steamids__1t5Zo {
    width: 260px;
    font-size: 16px;
    background-color: rgb(45, 62, 87);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.id-converter_steamid__2POIx {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 500px) {
    .id-converter_content__2NLca {
        width: 100vw;
    }

    .id-converter_steamids__1t5Zo {
        width: 240px;
        font-size: 14px;
    }
}
.api_container__3nXJF {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(220, 228, 241);
    overflow-wrap: break-word;
    text-align: center;
    max-width: 100%;
}

.api_column__3664j {
    display: flex;
    flex-direction: column;
}

.api_content__HIaYw {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: rgb(51, 72, 104);
    padding: 20px;
    border-radius: 5px;
}

.api_endpoint_container__2FqUY {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 500px;
    background-color: rgb(31, 44, 63);
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.api_endpoint_container_top__26MwZ {
    background-color: rgb(51, 72, 104);
    padding: 10px;
    margin: -20px;
    margin-bottom: 10px;
}

.api_endpoint_container_bottom__3IHdq {
    text-align: left;
    color: rgb(174, 188, 209);
}

.api_bold__2cRfu {
    font-weight: bold;
}

.api_title__1T8L2 {
    display: inline-flex;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid rgb(51, 72, 104);
    font-weight: bold;
    color: rgb(198, 211, 231);
}

.api_code__1H7d8 {
    font-size: 12px;
    padding: 5px;
    background: rgb(32, 32, 32);
    border-radius: 5px;
}

@media only screen and (max-width: 540px) {
    .api_container__3nXJF {
        width: 100%;
    }
    .api_column__3664j {
        width: 90vw;
        margin-right: 40px;
    }
    .api_content__HIaYw {
        width: 90vw;
        margin-right: 40px;
    }
    .api_endpoint_container__2FqUY {
        width: 90vw;
        margin-right: 40px;
    }

}
.bottom_container__2UhZ2 {
    min-height: 125px;
    margin-top: 10vh;
    background: linear-gradient(0deg, rgba(11,11,11,1) 0%, rgba(29,38,54,1) 100%);
    display: flex;
    justify-content: center;
}

.bottom_links__j7H10 {
    display: inline-flex;
}

.bottom_ul__2u0FL {
    padding: 0 20px 0 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgb(157, 176, 204);
    font-size: 18px;
    list-style: none;
}

.bottom_li__18ocH {
    cursor: pointer;
}

.bottom_ul__2u0FL a {
    text-decoration: none;
    color: rgb(157, 176, 204);
}

.bottom_first__1bl-f {
    font-size: 20px;
    color: rgb(216, 226, 241);
}

@media only screen and (max-width: 460px) {
    .bottom_links__j7H10 {
        font-size: 16px;
    }

    .bottom_ul__2u0FL {
        font-size: 16px;
    }
}

@media only screen and (max-width: 350px) {
    .bottom_links__j7H10 {
        font-size: 13px;
    }

    .bottom_ul__2u0FL {
        font-size: 13px;
    }
}
.modal {
    z-index: 15;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
}

.modal-box {
    border-radius: 5px;
    background-color:rgb(48, 64, 90);
    margin: 20vh auto; /* 15% from the top and centered */
    padding: 20px;
    max-width: 475px;
    min-height: 150px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    box-shadow: rgb(0, 0, 0) 0px 0px 50px 0.7px;
    padding-right: 44px;
}

.modal-close {
    position: absolute;
    color: #aaa;
    font-size: 28px;
    margin-top: -14px;
    right: 14px;
    font-weight: bold;
}

.modal-close:hover,
.modal-close:focus {
    color: rgb(116, 116, 116);
    text-decoration: none;
    cursor: pointer;
}

.modal-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

@media only screen and (max-width: 541px) {
    .modal-box {
        border-radius: 0;
    }
}
* {
    margin: 0;
    padding: 0;
    font-family: 'Play', 'Electrolize', 'Nunito', sans-serif;
}

body {
    /* background-color: #040609; */
    background-color: rgb(18, 24, 34);
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}

.container {
    background-color:rgb(29, 38, 54);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 1152px;
    box-shadow: rgb(54, 71, 100) 0px 0px 20px 1px; 
}

a:link {
    text-decoration: none;
}

.link {
    text-decoration: underline;
    -webkit-text-decoration-color: rgba(255, 255, 255, 0.25);
            text-decoration-color: rgba(255, 255, 255, 0.25);
    cursor: pointer;
}

.link:hover {
    -webkit-text-decoration-color: rgba(255, 255, 255, 0.75);
            text-decoration-color: rgba(255, 255, 255, 0.75);
}
  
