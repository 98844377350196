.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    max-width: 100%;
}

.title {
    display: inline-block;
    font-size: 18px;
    color: rgb(230, 237, 248);
    background-color: rgb(56, 74, 105);
    border-radius: 5px;
    padding: 10px;
    margin: -10px;
    margin-top: 0px;
    margin-bottom: 20px;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list_item {
    list-style: none;
    justify-content: center;
    display: inline-flex;
    background-color: rgb(42, 56, 78);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 350px;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.list_item:hover {
    background-color: rgb(54, 72, 100);
    color: rgb(223, 231, 245);
}

.pic_container {
    display: flex;
    width: 15%;
    align-items: center;
    justify-content: center;
    border-right: 2px solid rgb(67, 90, 126);
}

.pic {
    margin-right: 10px;
    border-radius: 100%;
    max-width: 32px;
    max-height: 32px;
    overflow: hidden;
    background-color: rgb(42, 56, 78);
}

.text_container {
    justify-content: center;
    text-align: center;
    width: 85%;
}

.list_item .text {
    color: rgb(148, 164, 189);
    margin-top: 5px;
}

.list_item_title {
    font-weight: bold;
    color: rgb(236, 236, 236);
}

.bottom {
    margin-top: 10px;
    font-weight: bold;
    color: rgb(198, 211, 231);
}

@media only screen and (max-width: 500px) {
    .ul {
        width: 100vw;
    }

    .list_item {
        width: 100%;
        margin-right: 1vh;
    }

    .pic_container {
        width: 15vh;
        margin-left: 2vh;
    }

    .text_container {
        width: 75vh;
    }
}