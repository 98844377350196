.main {
    margin-top: 30px;
    min-height: 80vh;
    color: rgb(198, 211, 231);
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.xmas {
    margin-top: 50px;
}

@media only screen and (max-width: 870px) {
    .main {
        max-width: 100%;
        margin-top: 90px;
    }

    .xmas {
        margin-top: 115px;
    }
}