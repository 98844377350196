#wire {
    pointer-events: none;
    text-align: center;
    white-space: nowrap;
    position: absolute;
    padding: 0;
    min-width: 1152px;
    top: 40px;
    border-bottom: 3px solid #222;
    height: 100px;
}

#wire li {
    pointer-events: none;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 15px;
    height: 30px;
    border-radius: 50%;
    margin: 0px 15px;
    top: 102px;
    display: inline-block;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: even-flash;
    animation-duration: 3s;
}

#wire li:nth-child(odd) {
    animation-name: odd-flash;
    animation-duration: 3s;
}

#wire li:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 10px;
    border-radius: 4px;
    top: -5px;
    left: 0px;
    background: #444;
}

@keyframes even-flash {
    0%,
    100% {
        background: rgba(30, 162, 86, 1);
        box-shadow: 0px 2px 20px 4px rgba(30, 162, 86, 1);
    }
    50% {
        background: rgba(30, 162, 86, 0.5);
        box-shadow: 0px 2px 20px 4px rgba(30, 162, 86, 0.3);
    }
}

@keyframes odd-flash {
    50% {
        background: rgba(187, 37, 40, 1);
        box-shadow: 0px 2px 20px 4px rgba(187, 37, 40, 1);
    }
    0%,
    100% {
        background: rgba(187, 37, 40, 0.5);
        box-shadow: 0px 2px 20px 4px rgba(187, 37, 40, 0.3);
    }
}

@media only screen and (max-width: 870px) {
    #wire {
        pointer-events: none;
        text-align: center;
        white-space: nowrap;
        position: absolute;
        padding: 0;
        min-width: 1152px;
        top: -40px;
        border-bottom: 3px solid #222;
        height: 100px;
        z-index: 2;
    }
}