.topnav {
    margin-bottom: 10px;
    height: 150px;
    
    /* background: url("../../../../src/assets/banner.png"); */
    /* border-bottom: 2px solid white; */
    /* box-shadow: rgb(75, 99, 139) 0px 0px 20px 1px;  */
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
    /* background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); */

    background-color:rgb(29, 38, 54);
    background-image: linear-gradient(
      to bottom, transparent, rgb(29, 38, 54)
    ), url("../../../../src/assets/banner.png");
    background-repeat: no-repeat;

}

.link_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.nav-links {
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    display: inline-flex;
    font-size: 1.75em;
    list-style: none;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
}

.t {
    margin-top: 10px;
    padding: 5px 10px 5px 10px;
    font-size: 1.25em;
}

/* background-color: rgb(0, 0, 0);
background-color: rgba(0, 0, 0, 0.5); */

.nav-links a {
    color: rgb(255, 255, 255);
    margin-right: 20px;
}

.nav-links > li:last-child {
    margin-right: -20px;
}

.list_item_wrapper {
    display: inline-flex;
    align-items: center;
}

.pic {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border-radius: 100%;
}

.dropdown {
    position: absolute;
    z-index: 2;
    background-color: black;
}

.top_mobile {
    display: none;
    justify-content: space-between;
    background-color:rgb(29, 38, 54);
    background-image: linear-gradient(
      to bottom, transparent, rgb(29, 38, 54)
    ), url("../../../../src/assets/banner.png");
    background-repeat: no-repeat;
    max-width: 100%;
    width: 100vw;
    height: 60px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    z-index: 10;

    color: rgb(231, 231, 231);
    vertical-align: middle;
    line-height: 20px;
}

.shadow {
    box-shadow: 0 0 20px rgba(0, 0, 0, 1);
}

.menu_link {
    padding: 20px;
}

.menu_link .signed_out {
    display: none;
}

.menu_link > a {
    text-decoration: none;
    color: rgb(231, 231, 231);
    font-size: 20px;
}

.menu_icon {
    margin-top: 2px;
    padding: 14px;
    margin-left: 6px;
}

.menu_icon div {
    width: 24px;
    height: 2px;
    background-color: rgb(231, 231, 231);
    margin: 6px 0;
}

.dropdown_menu {
    min-height: 60px;
    margin-top: 60px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    background-color:rgb(29, 38, 54);
    
    z-index: 9;
}

.dropdown_menu ul li a {
    text-decoration: none;
    color: rgb(231, 231, 231);
}

.dropdown_menu ul li {
    list-style: none;
    padding: 7.5px;
    margin-left: 15px;
    margin-right: 15px;
}

.dropdown_menu ul li:last-child {
    margin-bottom: 10px;
}

.dropdown_divider {
    display: flex;
    justify-content: space-between;
}

.xmas_lights_mobile {
    background-color: blue;
    visibility: hidden;
}

@media only screen and (max-width: 870px) {
    .topnav {
        max-width: 100%;
        display: none;
    }

    .top_mobile {
        display: flex;
    }

    .dropdown_menu {
        display: '';
    }

    .xmas_lights {
        visibility: hidden;
    }

    .xmas_lights_mobile {
        z-index: 6;
        position: fixed;
        visibility: visible;
    }
}

@media only screen and (min-width: 870px) {
    .dropdown_menu {
        display: none;
    }
}
