.container {
    cursor: pointer;
    display: flex;
    width: 100%;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.button {
    font-size: 15px;
    font-weight: bold;
    color: rgb(198, 211, 231);
    background-color: rgb(44, 59, 83);
    padding: 8px;
    min-width: 20px;
    text-align: center;
}

.button:hover {
    background-color: rgb(65, 87, 122);
}

.container ul li {
    list-style-type: none;
    display: inline-flex;
    justify-content: center;
}

.button.active {
    background-color: rgb(86, 116, 165);
    color: rgb(231, 237, 247);
}

.button.button_left {
    border-radius: 5px 0 0 5px;
}

.button.button_right {
    border-radius: 0 5px 5px 0;
}

@media only screen and (max-width: 400px) {
    .button {
        font-size: 90%;
        padding: 5px;
    }

    .button.button_left {
        border-radius: 2px 0 0 2px;
    }
    
    .button.button_right {
        border-radius: 0 2px 2px 0;
    }

}

@media only screen and (max-width: 330px) {
    .button {
        padding: 2px;
    }
}